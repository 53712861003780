<template>
  <b-card
    title="รายการผลที่ผ่านมา"
  >
    <b-row>
      <b-col
        md="2"
        sm="4"
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block  mr-50 text-sm-right font-small-4">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="pagination.rowsPerPage"
            size="sm"
            :options="rowsOptions"
            class="w-50"
            style="min-width: 100px"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="my-1"
      >
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filterForSearch"
              type="search"
              placeholder="Type RoundID to search"
              @keydown.enter="filterSearch"
            />
            <b-input-group-append>
              <b-button
                @click="filterSearch"
              >
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-table
          responsive
          :per-page="pagination.rowsPerPage"
          :current-page="pagination.page"
          :items="reloadData"
          :fields="fields"
          :sort-by.sync="pagination.sortBy"
          :sort-desc.sync="pagination.descending"
          :busy.sync="loading"
          :filter="filter"
          bordered
        >
          <template #cell(detailDispatch)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              @click="showDistpatch(data.item)"
            >
              <span class="align-middle">ดูการจ่าย</span>
            </b-button>
          </template>
        </b-table>
      </b-col>
      <b-col
        cols="12"
      >
        <b-pagination
          v-model="pagination.page"
          :total-rows="pagination.rowsNumber"
          :per-page="pagination.rowsPerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <b-modal
      v-model="dialog"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      :title="`${huayMapper[focus.yeekeeId] ? huayMapper[focus.yeekeeId].name : ''} วันที่ ${focus.createAt}`"
      button-size="sm"
      hide-footer
      size="sm"
    >
      <b-list-group class="mt-2 mb-2">
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สามตัวบน</span>
          {{ toNumber((focus.topThree|| 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สามตัวโต๊ด</span>
          {{ toNumber((focus.topThreeJump|| 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สองตัวบน</span>
          {{ toNumber((focus.topTwo|| 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สองตัวล่าง</span>
          {{ toNumber((focus.bottomTwo|| 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>วิ่งบน</span>
          {{ toNumber((focus.topRun|| 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>วิ่งล่าง</span>
          {{ toNumber((focus.bottomRun|| 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          variant="success"
        >
          <span>ยอดเดิมพันเครดิตทั้งหมด</span>
          {{ toNumber((focus.sumRollingAmount||0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          variant="danger"
        >
          <span>ยอดจ่ายเครดิตทั้งหมด</span>
          {{ toNumber((focus.sumWinAmount||0).toFixed(2)) }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect, BInputGroup, BFormInput, BInputGroupAppend, BPagination, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import lottoService from '@/service/lottoService'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BPagination,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      rowsOptions: [5, 10, 15, 25, 50, 100, 250, 500],
      filter: '',
      filterForSearch: '',
      loading: false,
      pagination: {
        sortBy: 'roundDate',
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 10,
      },
      fields: [
        {
          key: 'createAt',
          label: 'วันที่',
          class: 'text-nowrap',
        },
        {
          key: 'roundId',
          label: 'Round Id',
          class: 'text-center text-nowrap',
        },
        {
          key: 'yeekeeId',
          label: 'Name',
          formatter: v => this.huayMapper[v]?.name,
          class: 'text-nowrap',
        },
        {
          key: 'shootSum',
          label: 'ผลรวมยิงเลข',
          class: 'text-nowrap',
        },
        {
          key: 'numberResult',
          label: 'เลขที่ออก',
          class: 'text-center text-nowrap',
        },
        {
          key: 'detailDispatch',
          label: 'ดูการจ่ายรางวัล',
          class: 'text-center text-nowrap',
        },
      ],
      items: [],
      dialog: false,
      focus: {},
      huayMapper: {},
    }
  },
  computed: {
    toNumber() {
      return this.$store.state.app.toNumber
    },
  },
  beforeMount() {
    lottoService
      .getAuthen('yeekee/dict')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(v => {
            this.huayMapper[v.id] = v
          })
          this.fields[2].formatter = v => this.huayMapper[v].name
        }
      })
  },
  async mounted() {
    if (this.$store.state.app.isMaster(this.$store) === false) {
      this.fields.pop()
    }
  },
  methods: {
    async showDistpatch(row) {
      this.focus = row
      this.dialog = true
    },
    filterSearch() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filter = this.filterForSearch
    },
    async reloadData() {
      const {
        page,
        rowsPerPage,
        rowsNumber,
        sortBy,
        descending,
      } = this.pagination

      const startRow = (page - 1) * rowsPerPage
      const fetchCount = rowsPerPage === 0 ? rowsNumber : rowsPerPage

      this.loading = true

      const res = await lottoService.getAuthen(`history/result/yeekee?limit=${fetchCount}&page=${startRow}&filter=${this.filter}&sortBy=${sortBy}&descending=${descending}`)
      if (res.status === 200) {
        this.pagination.page = page
        this.pagination.rowsPerPage = rowsPerPage
        this.pagination.rowsNumber = res.data.count
        this.pagination.sortBy = sortBy
        this.pagination.descending = descending
        this.loading = false

        return res.data.list
      }
      return []
    },
  },
}
</script>
